import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import Home from "./Home";
import About from "./About";
import Academic from "./Academic";
import Tc from "./Tc";
import Admission from "./Admission";
import Facility from "./Facility";
import Faculty from "./Faculty";
import Result from "./Result";
import Gallery from "./Gallery";
import Vacancy from "./Vacancy";
import Contact from "./Contact";
import SchoolInformation from "./SchoolInformation";
import StudentArena from "./StudentArena";
import GetSingleNotice from "./GetSingleNotice";
import CbseFootballEastZone from "./Gallery/CbseFootballEastZone";
import School from "./Gallery/School";
import RepublicDay from "./Gallery/RepublicDay";
import AnnualDay from "./Gallery/AnnualDay";
import RepublicDay2023 from "./Gallery/RepublicDay2023";
import ChildrenDay from "./Gallery/ChildrenDay";
import IndependenceDay from "./Gallery/IndependenceDay";
import AnnualDay2022 from "./Gallery/AnnualDay2022";
import AnnualDay2023 from "./Gallery/AnnualDay2023";
import Excursion from "./Gallery/Excursion";
import Rangoli from "./Gallery/Rangoli";
import PrizeDistribution from "./Gallery/PrizeDistribution";
import Farewell from "./Gallery/Farewell";
import Calligraphy from "./Gallery/Calligraphy";
import mps from "../images/mps.webp";
import mpslogo from "../images/mpslogo.webp";
import { Container, Row, Col } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import Footer from "./Footer";
import Essay from "./Gallery/Essay";
import InternationalYogaDay from "./Gallery/InternationalYogaDay";
import ScienceExhibition from "./Gallery/ScienceExhibition";
import SportsDay from "./Gallery/SportsDay";
import RoadSafety from "./Gallery/RoadSafety";
import InHouseTraining from "./Gallery/InHouseTraining";
import CapacityBuildingProgram from "./Gallery/CapacityBuildingProgram";
import Excursion2023 from "./Gallery/Excursion2023";
import ClassroomManagement from "./Gallery/ClassroomManagement";
import AnnualSports from "./Gallery/AnnualSports";
import GandhiShastri2024 from "./Gallery/GandihiShastriJayanti2024";
import InHouseTrainingCareerGuidance from "./Gallery/InHouseTrainingCareerGuidance";
import CbseKhoKho from "./Gallery/CbseKhoKho";
import Loih2024 from "./Gallery/Loih2024";
import AnnualDay2024 from "./Gallery/AnnualDay2024.js";
import AnnualSports2024 from "./Gallery/AnnualSports2024.js";
import ScienceExhibition2024 from "./Gallery/ScienceExhibition2024.js";

// import { createBrowserHistory } from "history";

// const customHistory = createBrowserHistory();

let home = "/home";
const Menu = () => {
  return (
    <Router>
      <Container fluid>
        <Row>
          <Col>
            <div className="mpspay">
            <a
                    href="https://mpsckp.eschl.in/login.aspx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-hand-o-right"></i> Pay Fee Online
                  </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={8} xs={8} lg={4} xl={4}>
            <Row>
              <Col md={3} sm={3} xl={3} xs={4} lg={3} as={Link} to={home}>
                <img src={mps} alt="logo" className="logoinbox img-fluid" />
              </Col>
              <Col md={9} sm={9} xl={9} xs={8} lg={9}>
                <Navbar.Brand as={Link} to={home}>
                  <img src={mpslogo} alt="logo" className="kmps img-fluid" />
                </Navbar.Brand>
              </Col>
            </Row>
          </Col>
          <Col md={8} sm={4} xs={4} lg={8} xl={8}>
            <div className="header-info d-none d-sm-none d-md-block hidden-xs">
              <ul>
                <li>
                  <a href="tel:9955475737">
                    <i className="fa fa-phone-square"></i>+91 9955475737
                  </a>
                </li>
                <li>
                  <a
                    href="https://mpsckp.eschl.in/login.aspx"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-hand-o-right"></i> Pay Fee Online
                  </a>
                </li>
                <li>
                  <a href="mailto:mpsckp@gmail.com">
                    <i className="fa fa-envelope-o"></i>mpsckp@gmail.com
                  </a>
                </li>

                <li>
                  <a
                    href="http://www.mpsckp.com/online_application.php"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Admission Open
                  </a>
                </li>
              </ul>
            </div>
            <Navbar collapseOnSelect expand="lg">
              <Container>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link href="/home">
                      <i className="fa fa-home"></i> Home
                    </Nav.Link>
                    <Nav.Link href="/about">About Us</Nav.Link>
                    <Nav.Link href="/academic">Academic</Nav.Link>
                    <Nav.Link href="/admission">Admission</Nav.Link>
                    <Nav.Link href="/facility">Facility</Nav.Link>
                    <Nav.Link href="/faculty">Faculty</Nav.Link>
                    <Nav.Link href="/result">Result</Nav.Link>
                    <Nav.Link href="/mgallery">Gallery</Nav.Link>
                    <Nav.Link href="/vacancy">Join Us</Nav.Link>
                    <Nav.Link href="/contact">Contact Us</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
            {/* <Navbar collapseOnSelect expand="lg" >
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link as={Link} to={"/home"}>
                    <i className="fa fa-home"></i> Home
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/about"}>
                    About Us
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/academic"}>
                    Academic
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/admission"}>
                    Admission
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/facility"}>
                    Facility
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/faculty"}>
                    Faculty
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/result"}>
                    Result
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/gallery"}>
                    Gallery
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/vacancy"}>
                    Join Us
                  </Nav.Link>
                  <Nav.Link as={Link} to={"/contact"}>
                    Contact Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar> */}
          </Col>
        </Row>
      </Container>

      <div>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/academic">
            <Academic />
          </Route>
          <Route path="/tc">
            <Tc />
          </Route>
          <Route path="/admission">
            <Admission />
          </Route>
          <Route path="/facility">
            <Facility />
          </Route>
          <Route path="/faculty">
            <Faculty />
          </Route>
          <Route path="/result">
            <Result />
          </Route>
          <Route path="/mgallery">
            <Gallery />
          </Route>
          <Route path="/vacancy">
            <Vacancy />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/SchoolInformation">
            <SchoolInformation />
          </Route>
          <Route path="/StudentArena">
            <StudentArena />
          </Route>
          <Route path="/School">
            <School />
          </Route>
          <Route path="/RepublicDay">
            <RepublicDay />
          </Route>
          <Route path="/RoadSafety">
            <RoadSafety />
          </Route>
          <Route path="/InHouseTraining">
            <InHouseTraining />
          </Route>
          <Route path="/InternationalYogaDay">
            <InternationalYogaDay />
          </Route>
          <Route path="/AnnualDay">
            <AnnualDay />
          </Route>

          <Route path="/RepublicDay2023">
            <RepublicDay2023 />
          </Route>

          <Route path="/AnnualSports2023">
            <AnnualSports />
          </Route>
          <Route path="/ChildrenDay">
            <ChildrenDay />
          </Route>
          <Route path="/GansdhiShstri24">
            <GandhiShastri2024 />
          </Route>
          <Route path="/InHouseCareerGuidance">
            <InHouseTrainingCareerGuidance />
          </Route>
          <Route path="/CbseKhoKho">
            <CbseKhoKho/>
          </Route>
          <Route path="/IndependenceDay">
            <IndependenceDay />
          </Route>
          <Route path="/AnnualDay2022">
            <AnnualDay2022 />
          </Route>
          <Route path="/CbseFootball2024">
            <CbseFootballEastZone/>
          </Route>
          <Route path="/AnnualDay2023">
            <AnnualDay2023 />
          </Route>
          <Route path="/ScienceExhibition">
            <ScienceExhibition />
          </Route>
          <Route path="/SportsDay">
            <SportsDay />
          </Route>
          <Route path="/Excursion">
            <Excursion />
          </Route>
          <Route path="/Rangoli">
            <Rangoli />
          </Route>
          <Route path="/ClassroomManagement">
            <ClassroomManagement />
          </Route>
          <Route path="/PrizeDistribution">
            <PrizeDistribution />
          </Route>
          <Route path="/Farewell">
            <Farewell />
          </Route>
          <Route path="/Calligraphy">
            <Calligraphy />
          </Route>
          <Route path="/CapacityBuildingProgram">
            <CapacityBuildingProgram />
          </Route>
          <Route path="/Excursion2023">
            <Excursion2023 />
          </Route>
          <Route path="/Essay">
            <Essay />
          </Route>
          <Route path="/Loih2024">
            <Loih2024 />
          </Route>
          <Route path="/AnnualDay2024">
            <AnnualDay2024 />
          </Route>
          <Route path="/AnnualSports2024">
            <AnnualSports2024 />
          </Route>
          <Route path="/ScienceExhibition2024">
            <ScienceExhibition2024 />
          </Route>
          <Route path="/GetSingleNotice/:id" component={GetSingleNotice} />
          <Redirect from="/" to="/" />
          <Route path="*">
            <Home />
          </Route>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
};

export default Menu;
